import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SinglePost from '../components/SinglePost/SinglePost';

const blogPostTemplate = ({pageContext: { data }}) => (
  <Layout>
    <SEO title={data.title} />
    <SinglePost content={data} />
  </Layout>
)

export default blogPostTemplate;