import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import { Disqus } from 'gatsby-plugin-disqus'
import AuthorIcon from '../../assets/images/user.svg';
import TagIcon from '../../assets/images/label.svg';
import DateIcon from '../../assets/images/calendar.svg';
import { defaultImagesList } from '../../data/defaultImages';
import './SinglePost.css';
const slugify = require('@sindresorhus/slugify');

const SinglePost = ({content}) => {
    let disqusConfig = {
        identifier: `${content.id}`,
        title: `${content.title}`,
      }
    return (
    <>
        <h2 className='singlePost__postTitle'>{ content.title }</h2>
        <div className='singlePost__postInfo'>
            <div className='singlePost__author'>
                <img src={AuthorIcon} alt='author' className='postInfo__author' />
                <Link
                    to={`/${slugify(content.autor.name, {decamelize: false, customReplacements: [['&', '']]})}`}
                >
                    <span className='postInfo__author'>{ content.autor.name + ', ' }</span>
                </Link>
            </div>
            <div className="singlePost__tag">
                <img src={TagIcon} alt='category' className='postInfo__tag' /><span className='postInfo__tag'>{ content.categorie + ', '}</span>
            </div>
            <div className="singlePost__date">
                <img src={DateIcon} alt='date' className='postInfo__date' /><span className='postInfo__date'>{ moment(content.createDate ? content.createDate : content.createdAt).format("DD MMMM YYYY") + 'r.' }</span>
            </div>
            
        </div>
        <div className='singlePost__postImage' style={{background:`url(${ content.image !== null ? `https://media.graphcms.com/${content.image.handle}` : defaultImagesList[Math.floor(Math.random() * (3))].image }) center/cover no-repeat`}}></div>
        <div className='singlePost__postContent'>
            <span dangerouslySetInnerHTML={{ __html: content.content.html }} />
            <div className='singlePost__signature'>
                { content.autor.name }
            </div>
        </div>
        <Disqus config={disqusConfig} />
    </>
)};

export default SinglePost;